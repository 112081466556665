<template> 
<div>
	<div class="menus-mask" ref="masks" v-show="menu_show" @click="showMenus(0)" @touchmove.prevent></div>
	<div id="slider" ref="slider" :class="['menus-box', menu_show? 'menus-show' : '']">
		<div class="menus-head">
			<div class="menus-head-title borderbottom">设备品牌</div>
			<ul class="menus-tabs borderbottom">
				<li :class="{'active': cur_idx == index}" v-for="item, index in menu_tabs" @click="menusTabs(index)">
					<span v-text="item.name"></span>
					﻿<i class="mintui mintui-back mintui-arrowright"></i>
				</li>
				<li v-if="!not_sub" :class="{'active':cur_idx == menu_tabs.length}" @click="menusTabs(menu_tabs.length)">
					<span>请选择</span>
				</li>
			</ul>
		</div>
		<!--   数据盒子   -->
		<ul class="menus-ul" :style="{'left':-cur_index*50+'%'}">
			<!--   数据块   -->
			<li :class="['menus-li', {'cur':cur_index==i}, {'slider-content': i== 1}, {'cur2': childShow}]" v-for="menu,i in array" :ref="{'slider-content': i== 1}">
				<div class="menus-item borderbottom" v-for="item,j in menu" :class="[{'active':values[i]==j}, {'title':item.txt}]" @click="item.txt ? '' : menusSelet(item,i,j)">
					<a :class="[{'title':item.txt}]" :ref="item.txt" :id="item.txt" :name="item.txt">
						<p class="name">
							<span v-text="item.name == 'NUM' && item.txt ? 'number' : item.name"></span>
						</p>
						<i v-if="values[i]!==j&&item.child" class="mintui mintui-back mintui-arrowright"></i> 
					</a>
				</div>
			</li>
		</ul>
		<div class="slider-nav" ref="sliderNav" v-show="sliderShow">
			<ul class="slider">
				<li v-for="item, index in sliderArr"><a :alt="'#' + item">{{item == 'NUM' ? '*' : item}}</a></li>
			</ul>
		</div>
	</div>	
</div>
</template>

<script>
import '@/assets/css/menusSelet.css'
import pinyin from 'js-pinyin'
import $ from 'jquery' 
export default {
  name: 'brand',
  data () {
    return {
		selectedIndex: [], // 默认选中值
		// 选择城市
		list: [
			[],
			[]
		],
		brand: [], // 品牌列表
		model: [], // 型号列表
		sliderArr: [] ,// 检索条
		// 抽屉
		// 品牌关联
		phoneList: [],     //  品牌
		current_brand: '', // 选择后的品牌
		current_model: '', // 选择后的型号
		menu_show: 0,      // 弹窗控制器
		cur_idx: 0,        // 当前菜单索引
		menu_tabs: [],     // 菜单
		menus: [],         // 数据源
		array:  [],        // 选择器展示的数据【二维数组】
		values: [],        // 默认地址
		not_sub: 0,        // 没有下一级 隐藏tab'请选择'
		sliderArr: [],
		sliderShow: false,
		childShow: false,
    }
  },
	mounted () {
		this.getBrand();
		$('.slider-nav').on("click", "a", function () {
			var slider  = $('#slider');
			var target  = $(this).attr('alt');
			console.log(target)
			var cOffset = $('.slider-content').offset().top;
			if($('.slider-content '+target).length > 0){
				var tOffset = $('.slider-content '+target, slider).offset().top;
				var height  = $('.slider-nav', slider).height();
				var pScroll = (tOffset - cOffset);
				console.log(pScroll)
				$('.slider-content', slider).stop().animate({scrollTop: '+=' + pScroll + 'px'});
			}
		})
	},
	watch: {
    
	},
	computed: {
		cur_index: function() { //用于判断tabs位置以及窗口宽度
			return this.cur_idx < 1 ? this.cur_idx : this.cur_idx - 1
		}
	},
	methods: {
		//显示选择器
		showMenus: function(bool) {
			this.menu_show = bool
		},
		/**
		 * 查询手机品牌
		 */
		getBrand () {
			const vm = this;
			let brandLists = []
			let postData   = {
				sk: '9f43ef878eb48f2361324d32190dd689',
				discernbrand: '',
				discernmodel: '',
				channelNo: 'tcbx'
			}
			this.$http
			.post("/analysis/modelList", postData, 2)
			.then(function(response) {
				if (response.data.header.code == "A1000") {
					let res = response.data.body
					if(res.status == 1){
						if(res.data.length > 0){
							var arr = [];
							// 整理品牌
							res.data.forEach(e => { 
								if(arr.indexOf(e.discernbrand) < 0){
									arr.push(e.discernbrand)
								}
							})
							// 整理品牌集合
							var arr2 = []
							arr.map(e => {
								var obj = {
									name: e,
									child: []
								}
								arr2.push(obj)
							})
							// 型号赋值
							res.data.forEach(e => { 
								let index = arr2.findIndex(item => item.name == e.discernbrand);
								// console.log(index)
								arr2[index].child.push(e.discernmodel)
							})
							// 遍历品牌
							arr2.forEach((obj) => {
								// console.log(obj)
								var pattern   = new RegExp("^[0-9]");
								var sliderArr = []
								var _child = obj.child
								var c = [];
								var modelBox = {};
								modelBox['NUM'] = [];
								for(var i = 0; i < 26; i ++)
								{
									modelBox[String.fromCharCode((65 + i))] = [];
								}
								// 遍历型号
								_child.forEach((obj2) => {
									if(!obj2) return;
									var str = obj2.trim()
									var o2 = {}
									o2.name = obj2
									// 判断首字符是否为字母
									if (pattern.test(str[0])) {
										modelBox['NUM'].push(obj2)
									} else {
										let char = pinyin.getCamelChars(str[0])
										modelBox[char.toUpperCase()].push(obj2)
									}
									// c.push(o2)
								})
								modelBox['NUM'].sort();
								// console.log(that.sliderArr)
								// console.log(modelBox)
								var childs = []
								Object.keys(modelBox).forEach((e) => {
									let obj = modelBox[e]
									if(obj.length < 1){
										delete modelBox[e]
										return;
									}
									sliderArr.push(e)
									childs.push({name: e, txt: e});
									obj.map((e2) => {
										var o = {
											name: e2
										}
										childs.push(o)
									})
								})
								vm.sliderArr = obj.sliderArr = sliderArr
								obj.child    = childs
							})
							vm.brand = arr2
						}
					}
					vm.list[0] = vm.brand;
					vm.menus = vm.brand;
					vm.$set(vm.array, 0, vm.menus)         // 设置 一级
					vm.$set(vm.menu_tabs, 0, vm.menus[0]); // 设置 tabs
					// 设置默认值
					vm.$set(vm.values, 0, 0); // 设置一级选中状态
					// console.log(that.array)
					// console.log(that.values)
				} else {
					vm.$createToast({
						txt: response.data.header.msg,
						type: 'warn'
					}).show()
				}
			})
			.catch(function() {
				vm.$createToast({
					txt: '网络错误',
					type: 'error'
				}).show()
			});
		},
		// 选择品牌
		menusSelet: function(item, i, j) {
			console.log('level', item, i, j);
			this.not_sub = 0;
			this.$set(this.menu_tabs, i, item); // 先设置 tabs
			this.menu_tabs.splice(i + 1, this.menu_tabs.length); // 后清除 tabs
			this.array.splice(i + 1,  this.array.length);        // 先清除 数据
			this.values.splice(i + 1, this.values.length);       // 先清除 数据索引
			this.$set(this.values, i, j);                        // 设置数据索引
			if (item.child) { // 子类cities还是areas或者其他字段
				this.sliderArr  = item.sliderArr
				this.sliderShow = true
				this.childShow  = true
				this.cur_idx = i + 1;   // 当前 tabs高亮
				this.$set(this.array, i + 1, item.child) // 设置下一级型号数据
				// console.log(this.array)
			} else {
				this.current_brand = ''; // 清空当前选中品牌
				this.current_model = ''; // 清空当前选中型号      
				this.not_sub       = 1;  // 没有子类 即最后一项
				console.log('this.menu_tabs', this.menu_tabs)
				// console.log(this.menu_tabs)
				for (var k in this.menu_tabs) {
					if(k == 0) this.current_brand += this.menu_tabs[k].name //选中当前品牌
					else if(k == 1) this.current_model += this.menu_tabs[k].name //选中当前型号
				}
				console.log(this.current_brand)
				console.log(this.current_model)
				this.$parent.form.brand = this.current_brand + ',' + this.current_model
				this.showMenus(0); //关闭弹窗
				var storagedata = {
					cur_idx:    this.cur_idx,
					menu_tabs:  this.menu_tabs,
					array:      this.array,
					values:     this.values,
					menus_left: this.menus_left,
				};
				localStorage.setItem('storagedata', JSON.stringify(storagedata));//本地存储
			}
		},
		/**
		 * 查询手机型号
		 */
		getModel (brand, j) {
			const vm = this;
			let postData = {
				sk: '9f43ef878eb48f2361324d32190dd689',
				discernbrand: brand,
				discernmodel: '',
				channelNo: 'tcbx'
			}
			this.$http
			.post("/analysis/modelList", postData, 2)
			.then(function(response) {
				if (response.data.header.code == "A1000") {
					let res = response.data.body
					if(res.status == 1){
						if(res.data.length > 0){
							vm.model = []
							var pattern = new RegExp("^[0-9]");
							var sliderArr = []
							var modelBox  = {};
							modelBox['NUM'] = [];
							for(var i = 0; i < 26; i ++)
							{
								modelBox[String.fromCharCode((65 + i))] = [];
							}
							res.data.map(e => { 
								let str = e.discernmodel
								let obj = {
									name:  e.discernmodel
								}
								if (pattern.test(str[0])) {
									modelBox['NUM'].push(obj)
								} else {
									let char = pinyin.getCamelChars(str[0])
									modelBox[char.toUpperCase()].push(obj)
								}
							})
							modelBox['NUM'].sort();
							var childs = []
							Object.keys(modelBox).forEach((e) => {
								let obj = modelBox[e]
								if(obj.length < 1){
									delete modelBox[e]
									return;
								}
								sliderArr.push(e)
								childs.push({name: e, txt: e});
								obj.map((e2) => {
									e2.txt = ''
									childs.push(e2)
								})
							})
							vm.sliderArr = sliderArr
							// vm.array[0][j].child = childs
							// console.log(vm.array[0][j].child)
							vm.$set(vm.array[0][j], 'child', childs) // 设置 二级
							vm.$set(vm.values, 1, 0); // 设置二级选中状态 
							// console.log(vm.array)
						}
					}	
				} else {
					vm.$createToast({
						txt: response.data.header.msg,
						type: 'warn'
					}).show()
				}
			})
			.catch(function() {
				vm.$createToast({
					txt: '网络错误',
					type: 'error'
				}).show()
			});
		},
		changeHandler(index, item, selectedVal, selectedIndex, selectedText) {
		  this.getModel(selectedVal[0])
		},
		selectHandler(selectedVal, selectedIndex, selectedText) {
		  this.$emit('change', selectedText.join(' '));
		},
		cancelHandler() {
		  // 未选择关闭
		  console.log('cancel')
		},
	},
}
</script>
<style lang="scss" scoped>
.check-box{
  display: inline-block;
  box-sizing: border-box;
  height: 64px;
  width: 189px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  text-align: center;
  line-height: 64px;
  font-size: 28px;
  color: #CCCCCC;
  margin-bottom: 30px;
  margin-right: 30px;
  &.on{
    color: #208170;
    border-color: #208170;
  }
}
.check-box:nth-child(3n){
  margin-right: 0;
}
</style>